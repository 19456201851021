<template>
  <div class="user">
    <div class="search">
      <el-select v-model="value" placeholder="请选择">
        <el-option
          v-for="item in options"
          :key="item.label"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <el-input
        type="text"
        placeholder="请输入内容"
        v-model="searchValue"
      ></el-input>
      <el-button @click="handleReast">
        <span>重置</span>
      </el-button>
      <el-button type="primary" @click="handleSearch">
        <span>查询</span>
      </el-button>
    </div>
    <el-table
      :data="tableData"
      style="width: 100%"
      border
      :header-cell-style="{ 'text-align': 'center', background: '#f2f2f2' }"
      :cell-style="{ 'text-align': 'center' }"
    >
      <el-table-column label="用户ID" width="180">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{ scope.row.id }}</span>
        </template>
      </el-table-column>
      <el-table-column label="注册手机">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{
            scope.row.presEntityMobile
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="证件姓名">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{ scope.row.presEntityName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="注册时间">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{ scope.row.createTime }}</span>
        </template>
      </el-table-column>
      <el-table-column label="状态">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{
            scope.row.status == 0 ? "禁用" : "启用"
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button size="mini" @click="handleInfo(scope.$index, scope.row)"
            >编辑</el-button
          >
          <el-button
            size="mini"
            type="danger"
            @click="handleDelete(scope.row)"
            >{{ scope.row.status == 0 ? "启用" : "禁用" }}</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-size="10"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <!-- 详情弹窗 -->
    <el-dialog title="编辑" :visible.sync="dialogTableVisible">
      <div class="userInfo">
        <div class="items">
          <span class="title">用户ID：</span>
          <span>{{ nowInfo.id }}</span>
        </div>
        <div class="items">
          <span class="title">证件号码：</span>
          <span>{{
            nowInfo.presEntityCode ? nowInfo.presEntityCode : "-"
          }}</span>
        </div>
        <div class="items">
          <span class="title">用户类型：</span>
          <el-radio-group v-model="radio">
            <el-radio :label="'NORMAL'">普通（默认）</el-radio>
            <el-radio :label="'REVIEWER'">专家</el-radio>
          </el-radio-group>
        </div>
        <div class="items">
          <span class="title">专家照片：</span>

          <el-upload
            class="avatar-uploader"
            ref="imageUpload"
            :auto-upload="false"
            action="https://jbvote.cdcee.com/common/uploadStatic"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :headers="headerToken"
            :on-change="handleChange"
          >
            <img
              v-if="nowInfo.expertAvatar"
              :src="nowInfo.expertAvatar"
              class="avatar"
            />
            <div class="upload" v-else>
              <i class="el-icon-plus avatar-uploader-icon"></i>
            </div>
          </el-upload>
        </div>
        <div class="items">
          <span class="title">专家介绍：</span>
          <el-input
            type="textarea"
            :autosize="{ minRows: 4, maxRows: 8 }"
            placeholder="请输入内容"
            v-model="nowInfo.expertDesc"
          >
          </el-input>
        </div>
      </div>
      <div class="button">
        <el-button type="primary" @click="submit">提交</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      options: [
        {
          value: 1,
          label: "用户ID",
        },
        {
          value: 2,
          label: "注册手机",
        },
        {
          value: 3,
          label: "证件姓名",
        },
      ],
      value: 1,
      tableData: [],
      currentPage: 1,
      total: 0,
      searchValue: "", // 搜索内容
      dialogTableVisible: false,
      nowInfo: {},
      radio: "NORMAL",
    };
  },
  computed: {
    headerToken() {
      return { authentication: localStorage.getItem("access_token") };
    },
  },
  methods: {
    handleInfo(index, row) {
      this.nowInfo = row;
      this.dialogTableVisible = true;
      this.radio = this.nowInfo.userType;
      console.log(this.nowInfo);
    },
    handleDelete(row) {
      this.$axiosPost("userManager/disableOrActivate", { id: row.id }).then(
        (res) => {
          if (res.code == 0) {
            this.$message({
              message: "操作成功",
              type: "success",
            });
          }
          this.getList();
        }
      );
    },
    // 重置
    handleReast() {
      this.value = 1;
      this.searchValue = "";
      this.getList();
    },
    // 查询
    handleSearch() {
      this.getList();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.currentPage = val;
      this.getList();
    },
    // 会员列表
    getList() {
      let params = {
        current: this.currentPage,
        pageSize: 10,
      };
      // 根据value判断当前搜索
      switch (this.value) {
        case 1:
          params.id = this.searchValue;
          break;
        case 2:
          params.presEntityMobile = this.searchValue;
          break;
        case 3:
          params.presEntityName = this.searchValue;
          break;
      }
      this.$axiosGet("userManager/queryPage", params).then((res) => {
        console.log(res);
        this.tableData = res.data.records;
        this.total = res.data.total;
      });
    },
    handleAvatarSuccess(res, file) {
      this.nowInfo.expertAvatar = res.data;
      console.log(res, file, 5555);
    },
    // beforeAvatarUpload(file) {
    //   const isJPG = file.type === 'image/jpeg';
    //   const isLt2M = file.size / 1024 / 1024 < 2;

    //   if (!isJPG) {
    //     this.$message.error('上传头像图片只能是 JPG 格式!');
    //   }
    //   if (!isLt2M) {
    //     this.$message.error('上传头像图片大小不能超过 2MB!');
    //   }
    //   return isJPG && isLt2M;
    // }
    // 编辑用户提交
    submit() {
      let params = {
        id: this.nowInfo.id,
        userType: this.nowInfo.userType == "NORMAL" ? "REVIEWER" : "NORMAL",
        expertAvatar: this.nowInfo.expertAvatar,
        expertDesc: this.nowInfo.expertDesc,
      };
      this.$axiosPost("userManager/editUserInfo", params).then((res) => {
        console.log(res);
        if (res.code == 0) {
          this.$message({
            message: "操作成功",
            type: "success",
          });
          this.dialogTableVisible = false;
          this.imageUrl = "";
          this.introduce = "";
          this.getList();
        }
      });
    },
    // 修改文件名为时间戳
    handleChange(file) {
      let fileArr = file.name.split(".");
      let name = new Date().getTime();
      let fileType = fileArr[1];
      let f = new File([file.raw], name + "." + fileType);
      f.uid = file.uid; // new File 没有uid属性，会导致组件底层报错，这里手动加上
      file.raw = f; // 用f替换file的数据
      this.$refs.imageUpload.submit(); // 手动触发上传
    },
  },
  created() {
    this.getList();
  },
};
</script>

<style lang="less">
.user {
  .search {
    display: flex;
    align-items: center;
    margin-bottom: 38px;

    .el-input {
      width: 200px;
      margin: 0 10px;
    }
  }

  .pagination {
    margin-top: 50px;
    display: flex;
    justify-content: flex-end;
    margin-right: 50px;
  }

  .el-button {
    padding: 10px 20px;
  }

  .userInfo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    // width: 50%;
    margin-left: 60px;

    .items {
      width: 50%;
      margin-bottom: 30px;
      display: flex;
      align-items: center;

      img {
        width: 112px;
        height: 68px;
        background: #ccc;
      }

      .title {
        display: inline-block;
        width: 100px;
      }
      // .upload {
      //   border: 1px dashed #d9d9d9;
      // }
    }
  }
 
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }

  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
  }

  .avatar {
    width: 100px !important;
    height: 100px !important;
    display: block;
  }

  .button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .search {
    .el-button {
      width: 100px;
    }
  }
}
</style>
